<template>
 <div class="mx-auto  my-auto w-1/2">
    <div class="bg-gray-200 p-5">
      <div class="text-center m-10 bg-green-300">
          <div class="text-center bg-white h-full pt-5">
              <div class="inline-block">
                  <img
                      class="w-auto h-24"
                      style="display: block;margin:2px"
                      width="100" 
                      height="60"
                      src="https://norwservice.com/images/logo.png"
                      alt="Norwservice Tecnology"
                  />
              </div>     
              <div v-if="error" class="pb-3">
                  <p class="text-base font-medium text-gray-400" v-html="error"></p>
              </div>
              <div v-else class="pb-3">
                <div>
                    <h2 class="text-xl font-medium">Hola, {{ usuario }}</h2>
                    <p class="text-base font-medium text-gray-400">Tu cuenta ha sido validada Satisfactoriamente</p>
                </div>
                  <div class="pb-10">
                      <div class="">
                            <router-link to="/pages/login">
                              <button class="rounded-lg p-1 transition duration-500 ease-in-out text-white bg-gray-900 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 ...">
                              Haz click para iniciar sesión!!
                              </button>
                            </router-link>                                   
                      </div>
                  </div>
              </div>


          </div>
      </div>
    </div>
 </div> 
</template>

<script>
import { SET_ERROR } from '@/store/mutations.type'
import { VALIDA_USUARIO_TEMP } from '@/store/actions.type'


export default {
  name: 'RegistroUsuarioTempView',
  data () {
    return {
      usuario: '',
      error: ''
    }
  },

  computed: {
  },
  methods: {
  },
  mounted () {
    const formData = new FormData()
    formData.append('username', this.$route.query.username)
    formData.append('ticket', this.$route.query.ticket)

    this.usuario = this.$route.query.username
    this.$store.dispatch(VALIDA_USUARIO_TEMP, formData).then(response => {
          this.usuario = this.$route.query.username    
          console.log('Parametros de validación Mount Validar Registro: ', response)
    }, error => {
        this.$store.commit(SET_ERROR, error.statusText)
        this.error = error.data.error;
        console.log('Parametros de validación Mount Validar Registro: ', error.data.error)

    }) 
    
  }  

};
</script>

<style>
.register {
  padding: 1rem;
}
.title {
  text-align: center;
}
.form {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: rgba(19, 35, 47, 0.9);
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
.form-label {
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
  &:first-of-type {
    margin-top: 0rem;
  }
}
.form-input {
  padding: 10px 15px;
  background: none;
  background-image: none;
  border: 1px solid white;
  color: white;
  &:focus {
    outline: 0;
    border-color: #1ab188;
  }
}
.form-submit {
  background: #1ab188;
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: #0b9185;
  }
}
.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
</style>
